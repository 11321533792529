import React from 'react'

function UnderService() {
  return (
    <div>
        <h1>This page is under development. Please be patient.</h1>
    </div>
  )
}

export default UnderService